import React from 'react'
import web from '../images/web-development.png';
import bannerweb from '../images/banerprincipal.png';
import sweb from '../images/s-web.png';
import Dgf from '../images/DG.png';
import mark2 from '../images/marketing.png';
import foto from '../images/foto.png';
import vya from '../images/vya.png';
import m3d from '../images/m3d.png';
import pub from '../images/pub.png';
import posi from '../images/posicionamiento.png';
import desaweb from '../images/desarrolloweb.jpg';
import deseco from '../images/ecommerce.jpg';
import mark from '../images/marketingdigital.jpg';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const Home = () => {
  return (
    <div className="container py-3">

      <div class="row align-items-center">

        <div class="col">

          <div className='container'>

            <h1 class="text-center"> BIENVENIDOS A GESSIME </h1>
            <h3 class="text-center"> DESARROLLO DE SITOS WEBS PROFESIONAL Y MARKETING DIGITAL</h3>
            <img class="col " src={bannerweb} width="100%" height="100%" object-fit="cover" alt="bannerweb" />

          </div>
          {/* seccion presentacion */}
          <div className="clearfix py-5 ">

            <img class="col-md-3 float-md-end mb-3 ms-md-3" src={web} alt="webdevelopment" />

            <p class="lead">
              *Somos una empresa de Desarrollo WEB y Marketing Digital en Guadalajara, comprometida con ofrecer un excelente servicio el la creación y desarrollo para tu sitio web.
            </p>
            <p class="lead">
              *Desarrollamos tu proyecto a tu medida, con las mejores tecnologías.

            </p>

            <p class="lead">
              *Desarrollamos y damos mantenimiento de Sitios Webs, E-commerce, Marketing Digital y servicios generales de Diseño Gráfico.
            </p>

            <p class="lead">
              *Queremos ayudarte a poner tu negocio en el Mundo Digital, te frecemos soluciones practicas y exactas para todas tus necesidades.
            </p>

          </div>

        </div>
        
        {/* seccion servicios */}
        <div className='container'>

          <h1 class="text-center"> SERVICIOS QUE OFRECEMOS </h1>

        </div>

        <div className="container">

          <div class="row row-cols-1 row-cols-md-4 g-4">

            <div class="col">
              <div class="card">
                <img class="col" src={sweb} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Desarrollo WEB</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <img class="col" src={mark2} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Marketing Digital</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <img class="col" src={posi} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Posicionamiento SEO</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <img class="col" src={Dgf} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Diseño Gráfico</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <img class="col" src={foto} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Fotografia</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <img class="col" src={vya} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Video y Animacion</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <img class="col" src={m3d} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Modelado 3D</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <img class="col" src={pub} alt="web" />
                <div class="card-body text-center">
                  <h5 class="card-title">Publicidad Impresa</h5>
                  <Link to="/servicios" className="btn btn-primary">Ver más</Link>
                </div>
              </div>
            </div>

          </div>


        </div>
        {/* seccion descatacdos */}
        <div className='container py-5'>

          <h1 class="text-center"> SERVICIOS DESTACADOS </h1>

          <div id="carouselExampleCaptions" class="carousel slide">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="col " src={desaweb} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
                <div class="carousel-caption d-none d-md-block">
                  <Link to="/servicios" className="btn btn-success">DESARROLLO WEB A MEDIDA</Link>
                </div>
              </div>

              <div class="carousel-item">
                <img class="col " src={deseco} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
                <div class="carousel-caption d-none d-md-block">
                  <Link to="/servicios" className="btn btn-success">DESARROLLO DE TIENDAS ONLINE AUTO-ADMINISTRABLES</Link>
                </div>
              </div>

              <div class="carousel-item">
                <img class="col " src={mark} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
                <div class="carousel-caption d-none d-md-block">
                  <Link to="/servicios" className="btn btn-success">DESARROLLO DE MARKETING DIGITAL</Link>
                </div>
              </div>
            </div>

            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>

            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

        </div>


        {/* seccion boletin*/}
        <div className='container py-5'>

          <h2 class="text-center"> SUSCRIBETE A NUESTRO BOLETIN </h2>

        </div>

        <div className="container">
          <div class="mb-3">
            <label for="name" class="form-label">Tu Nombre y Apellido</label>
            <input type="text" class="form-control" id="name" placeholder="Nombre y Apellido"></input>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Correo Electronico</label>
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"></input>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">Dejanos un  Mensaje</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            <br />
            <Link to="/" className="btn btn-primary">ENVIAR</Link>
          </div>

        </div>


      </div>

    </div>

  )
}

export default Home