import React from 'react'
import bannerc from '../images/bannercontacto.jpg';
import Iframe from 'react-iframe';

const Contact = () => {
  return (
    <div className='container py-5'>
      <img class="col " src={bannerc} width="100%" height="100%" object-fit="cover" alt="bannerweb" />

      <div className='container'>
        <h1 class="text-center"> CONTACTANOS </h1>
        <h4 class="text-center"> ENVÍANOS UN MENSAJE, TE TOMARA SOLO 5 MINUTOS </h4>
      </div>
      {/* FORMULARIO*/}
      <div class="container text-center py-5">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="text-center"> ¡Podemos asesorarte en <strong> todos tus proyectos! </strong> </h1>
            <h4 class="text-center"> Somos un equipo de profesionales capacitados para llevar tu
              marca al siguiente nivel. Trabajamos enfocados en nuestros
              clientes, conscientes de que su éxito es nuestro éxito.

              Contamos con expertos especializados en las diferentes áreas
              del marketing digital, lo que nos permite trabajar de manera
              integral y proponer estrategias 360º. </h4>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">

                <form>
                  <div className='containar py-5'>
                    <div class="form-row">
                      <div class="form-group ">
                        <label for="nombre">Nombre y Apellido</label>
                        <input type="text" class="form-control" id="nombre" placeholder="Nombre y Apellido"></input>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="correo">Correo</label>
                      <input type="email" class="form-control" id="correo" placeholder="Correo"></input>
                    </div>
                    <div class="form-group">
                      <label for="telefono">Teléfono / WhatsApp</label>
                      <input type="tel" class="form-control" id="telefono" placeholder="Teléfono / WhatsApp"></input>
                    </div>
                    <div class="form-group">
                      <label for="mensaje">Mensaje</label>
                      <textarea class="form-control" id="mensaje" rows="3" placeholder="Mensaje"></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary">Enviar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mapa */}
      <div className='container'>
        <h1 class="text-center"> NUESTRA UBICACION  </h1>
        <br />
        <div className="container">
      <div className="row justify-content-center">
      <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.755023494147!2d-103.31361882477799!3d20.63883990105673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b3b367628449%3A0xc143d37a270c8d66!2sEl%20Pari%C3%A1n%20de%20Tlaquepaque!5e0!3m2!1ses-419!2smx!4v1714450977743!5m2!1ses-419!2smx"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="embed-responsive-item"
            />
      </div>
    </div>
      </div>


    </div>
  )
}

export default Contact