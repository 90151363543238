import React from 'react';
import sweb from '../images/s-web.png';
import banern from '../images/bannern.png';
import Nvalores from '../images/valores.jpg';
import mision from '../images/mision.svg';
import vision from '../images/vision.svg';
import valores from '../images/valores.svg';
import { Link } from 'react-router-dom';
import mynec from '../images/mynec.png';
import tina from '../images/tina.png';
import mexi from '../images/mexicanias.png';
import rbgi from '../images/rbgimpresos.png';
import bosch from '../images/bosch.png';
import mikels from '../images/mikels.png';
import redtool from '../images/redtool.png';
import urrea from '../images/urrea.png';


const About = () => {
  return (
    <div className='container'>
      <img class="col " src={banern} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
      <div d-none d-md-block>
        <h1 class="text-center"> LA HISTORIA DE GESSIME  </h1>
        <h4 clas="text-center"> Somos una empresa Mexicana establecida en la Ciudad de México que se enfoca principalmente en brindar Soluciones Integrales de Diseño y Desarrollo Web con la finalidad de potencializar al máximo los negocios de nuestros clientes en Internet.</h4>
      </div>

      {/* HISTORIA */}
      <div class="container py-5">
        <div class="row">

          <div class="col">

            <h1 className="text-center"> Nuestra Historia</h1>

            <h4 className="text-center">
              Hemos estado desarrollando nuestra actividad; con creatividad, investigando, desarrollando, innovando y creciendo con nuevas ideas creando estrategias de marketing personalizadas para cada uno de nuestros clientes, para llegar junto con ellos a los objetivos que se plantean.
            </h4>

            <h4 className="text-center">
              Nos basamos principalmente en crear las mejores páginas web, más novedosas y que estas estén optimizadas para que sean visibles tanto en web como para móvil, y posicionar tu negocio en los primeros lugares de los buscadores, ya que esto va de la mano para llegar al éxito de cualquier negocio en internet.
            </h4>

            <h4 className="text-center">
              Somos una empresa polivalente, lo cual nos permite adquirir una posición de proximidad con nuestros clientes y ponernos sus zapatos para trabajar juntos en la construcción de la solución más eficaz que ayude a incrementar sus resultados.
            </h4>



          </div>

          <div class="col text-center">
            <img class="col " src={sweb} width="70%" height="70%" object-fit="cover" alt="bannerweb" />
            <h4 lassName="text-center" >
              Nuestro equipo Multidisciplinario se compone por Ingenieros Informáticos, Mercadólogos, Diseñadores Gráficos, Administrativos, Consultores, Fotógrafos, Publicistas, Especialistas SEO, y Redactores.
            </h4>

          </div>
        </div>
      </div>

      {/* VALORES */}
      <div className="container">
        <img class="col " src={Nvalores} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
        <div d-none d-md-block>
          <h1 class="text-center"> NUESTROS VALORES  </h1>
          <h4 clas="text-center"> Somos una empresa Mexicana establecida en la Ciudad de México que se enfoca principalmente en brindar Soluciones Integrales de Diseño y Desarrollo Web con la finalidad de potencializar al máximo los negocios de nuestros clientes en Internet.</h4>
        </div>

        <div class="row row-cols-1 row-cols-md-3 g-4">
          <div class="col">
            <div class="card">
              <img class="card-img-top" src={mision} width="50%" height="50%" object-fit="cover" alt="..."></img>
              <div class="card-body">
                <h5 class="card-title">NUESTRA MISION</h5>
                <p class="card-text">Satisfacer a nuestros clientes a través de un buen servicio y creando sitios con las especificaciones y características que cada uno necesite, con un punto estratégico de comercialización para el Desarrollo y Diseño Web Empresarial.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img class="card-img-top" src={vision} width="100%" height="100%" object-fit="cover" alt="..."></img>
              <div class="card-body">
                <h5 class="card-title">NUESTRA VISION</h5>
                <p class="card-text">Posicionarnos en CDMX, en el Desarrollo y Diseño Web Empresarial, distinguiéndonos por un buen servicio. Teniendo el compromiso con los clientes y los empleados del negocio, fomentando un crecimiento continuo.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img class="card-img-top" src={valores} width="100%" height="100%" object-fit="cover" alt="..."></img>
              <div class="card-body">
                <h5 class="card-title">NUESTROS VALORES</h5>
                <p class="card-text">Todos los que conforman Evolución Web MX, tienen la responsabilidad de actuar con base a los valores que fomenta la organización, haciendo de éste un negocio preocupado por el crecimiento de cada uno de sus integrantes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      {/* PROYECTOS */}
      <div className='container'>

        <h1 class="text-center" > ¡¡¡ PROYECTOS QUE HEMOS REALIZADO !!! </h1>

      </div>
      <br />
      <br />

      <div className="container">

        <div class="row row-cols-1 row-cols-md-4 g-4">

          <div class="col">
            <div class="card">
              <img class="col" src={mynec} alt="web" />
              <div class="card-body text-center">
                <h5 class="card-title">Web Mynec</h5>
                <p> Desarrollo del sitio web para la empresa de Mynec.</p>
                <Link to="https://mynec.mx/" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <img class="col" src={tina} alt="web" />
              <div class="card-body text-center">
                <h5 class="card-title">WEB TINA Autismo</h5>
                <p> Desarrollo del sitio web para la asociación civil Tina Autismo</p>
                <Link to="https://tinautismo.lat/" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <img class="col" src={mexi} alt="web" />
              <div class="card-body text-center">
                <h5 class="card-title">WEB Mexicanias SEO</h5>
                <p>Desarrollo del sitio web Mexicanias</p>
                <Link to="https://mexicanias.mx/" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <img class="col" src={rbgi} alt="web" />
              <div class="card-body text-center">
                <h5 class="card-title">WEB RBG Impresos</h5>
                <p>Desarrollo del sitio web de la empresa RBG Impresos</p>
                <Link to="https://rbgimpresos.com.mx/" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <img class="col" src={bosch} alt="web" />
              <div class="card-body text-center">
                <h5 class="card-title">Ecommerce BOSCHSTORE</h5>
                <p>Desarrollo Sitio Ecommerce para BoschStore</p>
                <Link to="https://boschstore.com.mx/" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <img class="col" src={mikels} alt="web" />
              <div class="card-body text-center">
              <h5 class="card-title">Ecommerce MIKELS</h5>
                <p>Desarrollo Sitio Ecommerce para MIKELS</p>
                <Link to="https://mikelstools.com.mx" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <img class="col" src={redtool} alt="web" />
              <div class="card-body text-center">
              <h5 class="card-title">Ecommerce REDTOOL</h5>
                <p>Desarrollo Sitio Ecommerce para REDTOOL</p>
                <Link to="https://www.redtool.com.mx/" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <img class="col" src={urrea} alt="web" />
              <div class="card-body text-center">
              <h5 class="card-title">Ecommerce Tienda URREA</h5>
                <p>Desarrollo Sitio Ecommerce para Tienda URREA</p>
                <Link to="https://www.tienda-urrea.com/" className="btn btn-primary">Ver más</Link>
              </div>
            </div>
          </div>

        </div>
      </div>

      </div>
      )
}

      export default About