import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './pages/Navbar';
import Footer from './pages/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import Store from './pages/Store';
import Contact from './pages/Contact';
import Error404 from './pages/Error404';



function App() {
  return (

    <div className=" text-light bg-black " >

      <BrowserRouter>
        
        <Navbar />
        
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/nosotros' element={<About />} />
            <Route path='/servicios' element={<Service />} />
            <Route path='/tienda' element={<Store />} />
            <Route path='/contacto' element={<Contact />} />
            <Route path='/*' element={<Error404 />} />

          </Routes>
        
        <Footer />

      </BrowserRouter>




    </div>
  );
}

export default App;
