import React, { useEffect, useState } from 'react';
import axios from 'axios';

const WooCommerceProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://rbgimpresos.com.mx/wp-json/wc/v3/products', {
          auth: {
            username: 'ck_285b58339e03f86d5d1e35a28c1b4404ada8ee47',
            password: 'cs_5d8a3117dd87e5ba446b225c31d7f6dbdf2d666d'
          }
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (

    <div className="container">

    <div className="row row-cols-1 row-cols-md-4 g-4" py-5>
      {products.map(product => (
        <div key={product.id} className="col">
          <div className="card h-100">
            <img src={product.images[0].src} className="card-img-top" alt={product.name} />
            <div className="card-body">
              <h5 className="card-title">{product.name}</h5>
              <p className="card-text">{product.sku}</p>
              
              <p className="card-text">Precio: ${product.price}</p>
              <a href={product.permalink} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Ver en tienda</a>
            </div>
          </div>
        </div>
      ))}
    </div>

    </div>
    );
};

export default WooCommerceProducts;
