import React from 'react'
import { IoLogoWhatsapp } from "react-icons/io";
import { GrMail } from "react-icons/gr";
import { BsFacebook } from "react-icons/bs";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { GrYoutube } from "react-icons/gr";
import { FaTiktok } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='container text-center py-5'>
      <div className="row justify-content-center">
        <div className="col">
          <h2>UBICACIÓN</h2>
          Calle Juárez 68, Centro, 45500 San Pedro Tlaquepaque, Jalisco, México.
        </div>
        <div className="col">
          <h2>CONTACTO</h2>
          <Link to="https://wa.me/5213328667938/?text=Hola%20Gessime%20quiero%20informacion%20de%20los%20servicios" target="_blank">
            <IoLogoWhatsapp /> 33-2866-7938
          </Link>
          <br />
          <Link to="mailto:admin@gessime.com" target="_blank">
            <GrMail /> contacto@gessime.com
          </Link>
        </div>
        <div className="col">
          <h2>REDES SOCIALES</h2>
          <div className="row">
            <div className="col">
              <Link to="https://www.facebook.com/GessimeMX" target="_blank">
                <BsFacebook /> Facebook: /GessimeMX
              </Link>
            </div>
            <div className="col">
              <Link to="https://www.instagram.com/gessimemx/" target="_blank">
                <BiLogoInstagramAlt /> Instagram: /gessimemx
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Link to="https://www.youtube.com/@GessimeMX" target="_blank">
                <GrYoutube /> Youtube: /GessimeMX
              </Link>
            </div>
            <div className="col">
              <Link to="http://tiktok.com/@gessimemx" target="_blank">
                <FaTiktok /> @GessimeMX
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer