import React from 'react';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';


const Navbar = () => {
    return (
        <div className='container-xl py-3'>
            <div className='row align-items-center'>
                <div className='col'>
                    <Link to="/"><img src={logo} alt="logo" width={250} /></Link>

                </div>
                <div className='col'>
                    <ul class=" nav justify-content-end">
                        <li class="nav-item">
                            <Link class=" nav-link active" aria-current="page" to="/">Inicio</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/nosotros">Nosotros</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/servicios">Servicios</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/tienda">Tienda</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/contacto">Contacto</Link>
                        </li>

                    </ul>

                </div>
            </div>

            
        </div>
    )
}

export default Navbar