import React from 'react'
import { Link } from 'react-router-dom';
import baners from '../images/banners.jpg';
import sdweb from '../images/sdesarrolloweb.jpg';
import sdecommerce from '../images/secommerce.jpg';
import sdmarketing from '../images/smarketing.jpg';



const Service = () => {
  return (
    <div className='container py-5'>

      <img class="col " src={baners} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
      <div d-none d-md-block>
        <h1 class="text-center py-5"> SERVICIOS QUE OFRECEMOS EN GESSIME  </h1>
        <h4 class="text-center"> El desarrollo y diseño web son la columna vertebral de una buena estrategia digital. Todo parte de ahí. Y todo vuelve hacia allí. ¿Qué queremos decir con esto? Que toda gran estrategia parte de un gran sitio web, ya que todos los esfuerzos posteriores se volcarán en hacer aumentar el tráfico de dicho sitio.

          En GESSIME desarrollamos sitios web con fines de uso interno, comercial o publicitario. Trabajamos bajo la guía de las últimas tendencias del mercado para ofrecer lo mejor que tenemos para cada empresa. Creamos desarrollos que no solo funcionan, sino que son sumamente atractivos para el usuario.</h4>
      </div>

      {/* SERVICIOS*/}
      <div className="container py-5">

        {/* DESARROLLO WEB*/}
        <div class="container text-center">
          <h1 class="text-center"> SERVICIOS DESARROLLO WEB A MEDIDA </h1>
          <div class="row py-5">
            <div class="col">
              <h1> TU IMAGINACION ES EL LIMITE </h1>
              <p>
                Diseño Web creativo y profesional enfocado a incrementar el potencial de su empresa. Una página web será la herramienta perfecta para atraer más clientes. Al tener una página web en Internet demostrara profesionalismo y credibilidad a sus proveedores y futuros clientes.

                Al iniciar el diseño web df de su sitio analizamos, planificamos y estructuramos la página web de acuerdo a las necesidad y requerimientos del mercado; el desarrollo web es en base a sus servicios o productos o giro empresarial. Siempre enfocamos su diseño web para impactar a sus clientes y prospectos y así obtener mayor ingresos en sus ventas.

                Cada diseño web está enfocado en posicionar su empresa en Internet y en la mente del consumidor digital, a través de utilizar su imagen corporativa y una redacción profesional basada en conectar con el cliente.
              </p>
            </div>
            <div class="col">
              <h1>LAS MEJORES TECNOLOGIAS</h1>
              <p>
                Para el desarrollo de las paginas web a medidas manejamos los mejores Frameworks  que nos permiten velocidad, usabilidad y escalabilidad.
                <div className='lista py-5'>
                  <ul> <h5>*REACTJS</h5> </ul>
                  <ul> <h5>*VUEJS</h5> </ul>
                  <ul> <h5>*ANGULAR</h5> </ul>
                  <ul> <h5>*NEXTJS</h5> </ul>
                  <ul> <h5>*LARAVEL</h5> </ul>
                </div>
              </p>
            </div>
            <div class="col">
              <img class="col " src={sdweb} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
            </div>
          </div>
        </div>

        {/* DESARROLLO ECOMMERCE*/}
        <div class="container text-center py-5">
          <h1 class="text-center"> SERVICIOS DESARROLLO ECOMMERCE </h1>
          <div class="row py-5">
            <div class="col">
              <img class="col " src={sdecommerce} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
            </div>
            <div class="col">
              <h1>SISTEMAS CMS ADMINISTRABLES</h1>
              <p>
                Podemos elevar tu negocio en el ambiente digital con los mejores CRM autoadministrables para crear una tienda online totalmente funcional y profesional.
                <div className='lista py-5'>
                  <ul> <h5>*WORDPRESS</h5> </ul>
                  <ul> <h5>*WOCOMMERCE</h5> </ul>
                  <ul> <h5>*PRESTASHOP</h5> </ul>
                  <ul> <h5>*SHOPIFY</h5> </ul>
                </div>
              </p>
            </div>
            <div class="col">

              <h1> LOS MEJORES SISTEMAS CRM ADMINISTRABLES PARA TU TIENDA EN LINEA </h1>
              <p>
                Primeramente el e commerce o también llamado comercio electrónico, es el intercambio de productos o servicios empleados mediante Internet.

                Actualmente en México el e commerce tiene alto impacto; por ser una tienda online que se puede iniciar con una inversión menor. Además de brindar acceso a una variedad de usuarios de alrededor del mundo.

                De esta manera la inversión de una tienda en línea es una manera de generar ingresos rápidamente para tu empresa o negocio.
              </p>
            </div>
          </div>
        </div>

        {/* DESARROLLO MARKETING DIUGITAL*/}
        <div class="container text-center py-5">
          <h1 class="text-center"> SERVICIOS DESARROLLO ECOMMERCE </h1>
          <div class="row py-5">
            <div class="col">
            <h1> IMPULSAMOS TU EMPRESA POR MEDIO DE LAS REDES SOCIALES </h1>
              <p>
              ¡En GESSIME los límites no nos detienen! Analizamos el contexto para crear ideas que trascienden.
              La investigación de mercado y benchmarking permiten comprender e implementar una campaña creativa que le ayude a sobresalir a tu marca, empresa o asociación.
              </p>
              <p>
              ¡En GESSIME los límites no nos detienen! Analizamos el contexto para crear ideas que trascienden.
              La investigación de mercado y benchmarking permiten comprender e implementar una campaña creativa que le ayude a sobresalir a tu marca, empresa o asociación.
              <h5> ¿Por qué una campaña creativa?</h5>
              
              Una campaña creativa es necesaria para lograr una diferenciación entre los cientos de marcas que nacen cada minuto. Toda empresa tiene un mensaje para su público objetivo y para que este llegue, diferenciarse es vital y esto puede lograrse a través de una agencia creativa. Como expertos sabemos que una buena campaña creativa es provocativa, inteligente, integral, innovadora e impactante; además debe estar bien implementada para que dé resultados.
              </p>
            </div>
            <div class="col">
              <h1>¡Creamos la estrategia creativa que tu empresa necesita!</h1>
              <p>
              Una gran idea es parte de una estrategia ganadora, además trae beneficios como estos:

                <div>
                  <ul> <h5>1.- Llega al público correcto.</h5> </ul>
                  <ul> <h5>2.- Impacta de forma positiva a tu target.</h5> </ul>
                  <ul> <h5>3.- Quédate en la mente del público objetivo.</h5> </ul>
                  <ul> <h5>4.- Genera Brand Awareness.</h5> </ul>
                  <ul> <h5>5.- Aumenta tus objetivos de marketing: leads, compras, etc.</h5> </ul>
                  <ul> <h5>6.- Genera alianzas con otros medios como televisión, cine, etc.</h5> </ul>
                  <ul> <h5>7.- Conoce a tus usuarios por medio de sus comportamientos.</h5> </ul>
                </div>
              
              </p>
            </div>
            <div class="col">
            <img class="col " src={sdmarketing} width="100%" height="100%" object-fit="cover" alt="bannerweb" />
              
              
            </div>
          </div>
        </div>

      </div>

      {/* FORMULARIO */}
      <div className="container py-5">
        <div className="text-center">
          <h1>Cotiza o Solicita Información de Algún Servicio </h1>
        </div>

        <div class="mb-3">
          <label for="name" class="form-label">Tu Nombre Completo</label>
          <input type="text" class="form-control" id="name" placeholder="Nombre Completo"></input>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Correo Electronico</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"></input>
        </div>
        <div class="mb-3">
          <label for="Tel/What" class="form-label">Telefono / WhatsApp</label>
          <input type="Text" class="form-control" id="Tel/What" placeholder="Telefono / WhatsApp"></input>
        </div>
        <div className="mb-3">
          <select class="form-select" aria-label="Default select example">
            <option selected>Selecciona el Servicio </option>
            <option value="1">Desarrollo WEB</option>
            <option value="2">Desarrollo de Ecommerce</option>
            <option value="3">Desarrollo de Marketin Digital</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          <br />
          <Link to="/" className="btn btn-primary">Ver más</Link>
        </div>

      </div>
    </div>
  )
}

export default Service