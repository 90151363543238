import React from 'react'
import WooCommerceProducts from './WooCommerceProducts';


const Store = () => {
  return (

    <><div className="container">
      <h1 className='text-center'> ADQUIERE NUESTROS PRODCUTOS Y SERVICIOS </h1>
    </div><WooCommerceProducts /></>


  )
}

export default Store